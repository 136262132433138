/* ==============================
  base
============================== */



@mixin position_center() {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
//
//
//
// $pc_width: 750px;
//
// $pc_header_hight: 60px;
// $sp_header_hight: 50px;
//
// $font_mincho: 'Libre Caslon Display',"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
//

$color_blue: #002A5C;
//
// $box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

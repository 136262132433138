@import "../mixins/media";
@import "base";


//product list 共通

.productList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a { color: #666666;}

  &__item {
    position: relative;
    margin-bottom: 10px;
    width: 49%;
    background: #eaeaea;
    border-radius: 5px;
    overflow: hidden;
    @include media_desktop {
      width: calc(98.5% /4);
      &:nth-child(5),
      &:nth-child(6){
        display: none;
      }
    }

    &Img {
      position: relative;
    }
    .itemTag {
      position: absolute;
      top:0;
      left: 50%;
      transform: rotate(40deg) translate(-10px, 25px);
      width: 100%;
      text-align: center;
      background: rgba(0,0,0,0.75);
      color: #fff;
      font-size: 1.3rem;
      z-index: 1;
    }
    &Data {
      padding: 10px;
    }
    .itemData {
      &--name {
        font-size: 1.2rem;
      }
      &--price {
        text-align: right;
        .fa-yen-sign {
          margin-right: 2.5px;
          font-size: 1.5rem;
        }
        .deco_tax {font-size: 1.2rem;}
      }
    }

  }

}


.ec-sliderRole {
  padding: 0;
  max-width: 100%;

  .slick-slide {
    opacity: 0.5;
    &.slick-current { opacity: 1;}
    img {
      display: block;
    }

  }

  .slick-dots {
    display: flex;
    justify-content: space-between;


    li {
      height: 5px;
      background: #d8d8d8;
      overflow: hidden;
      &.slick-active {
        background: $color_blue;
      }
      button {
        width: 100%;
        opacity: 0;
      }
    }
  }
}

.ec-matryoshkaRole {
  position: relative;

  .matryoshkaImg {
    position: relative;
    display: block;
    margin-bottom: 10px;

    @include media_desktop {
      height: 450px;
      background: url(../img/index/mat/matryoshka.jpg) no-repeat bottom center;
      background-size: cover;

    }

    &__text {
      @include position_center;
      padding: 15px;

      background: rgba(0,0,0,0.4);
      color: #fff;

      @include media_desktop {
        padding: 35px;
        background: rgba(0,0,0,0.7);
      }

      &--h {
        display: block;
        font-weight: normal;
        font-size: 3rem;
        letter-spacing: .1rem;
        font-family: 'Cormorant Garamond', serif;

        @include media_desktop {
          font-size: 4rem;
          padding-bottom: 10px;
        }
      }

      &--b {
        font-size: 1.2rem;
        @include media_desktop {
          font-size: 1.5rem;
        }
      }

      &--more {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    &__img {
      @include media_desktop {
        display: none;
      }
    }


  }

  .matryoshkaCategory {
    display: flex;
    justify-content: space-between;

    @include media_desktop{
      // position: absolute;
      // right: 0;
      // bottom: 0;
      // width: 50%;
      // height: 347.328px;
      // margin-bottom: 10px;
    };

    &__item {
      position: relative;
      width: 49%;
      background: #042a5c;
      border-radius: 5px;
      overflow: hidden;

      @include media_desktop{
        width: 49.8%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img { width: 60%;}
      }

      &Text {
        padding: 5px 10px;
        width: 100%;
        height: 55px;
        color: #fff;
        font-weight: bold;

        @include media_desktop{
          font-size: 2.2rem;
          text-align: center;

        };

        .more {
          display: none;
        }


      }

    }
  }

}

.arrival {
  padding-right: 0;
  overflow: hidden;
  @include media_desktop {
    padding-right: 0px;
    padding-left: 0px;
  }
  .ec-arrivalRole__product {
    position: relative;
  }
  .arrival_arrows {
    position: absolute;
    height: 100%;
    width: calc(100vw - 20px);
    //z-index: 1;
    @include media_desktop {
      width: 1130px;
    };


    .slick-arrow {
      position: absolute;
      top:50%;
      border: none;
      border-radius: 100%;
      background: rgba(0,0,0,0.2);
      font-size: 0;
      appearance:normal;
      z-index: 1;

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;

        @include media_desktop {
          width: 15px;
          height: 15px;
        };
      }


      &.slick-prev {
        width: 0px;
        height: 0px;
        left:0;
        transform: translate(0,-75%);

        @include media_desktop {
          width: 50px;
          height: 50px;
          left:20px;
        };

        &::after {
          display: none;
          left: 35%;
          margin-top: -8px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(135deg);
          @include media_desktop {
            display: block;
          };
        }


      }

      &.slick-next{
        width: 30px;
        height: 30px;
        right: 0;
        transform: translate(0,-50%);

        @include media_desktop {
          width: 50px;
          height: 50px;
          right: 20px;
        };

        &::after {
          right: 36%;
          margin-top: -5px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(-45deg);

          @include media_desktop {
            right: 35%;
            margin-top: -8px;
          };
        }


      }
    }
  }
}
.ec-arrivalRole {
  position: relative;
  &::after {
    position: absolute;
    right: 0;
    top:0;
    width: 50px;
    height: 100%;
    background: linear-gradient(270deg, #fff, rgba(255,255,255,0));
    content: "";

    @include media_desktop {
      display: none;
    }

  }

  &__product {

    width: 120%;

    @include media_desktop {
      width: 100%;
    }


  }

  .productList {
    display: block;

    @include media_desktop {
      .slick-track{
        justify-content: flex-start;

      }
    }

    &__item {
      width: 40%;
      margin-right: 10px;
      @include media_desktop {
        width: 25%;
        margin-right: 5px;
        margin-left: 5px;


      }
      &:nth-child(5),
      &:nth-child(6){
        display: block;
      }


    }

  }

}

.blog {
  position: relative;
  background: #000;
  color: #fff;
  text-shadow: 0 0 1px #fff;

  &::before,&::after {
    position: absolute;
    left: 0;
    width: 100%;
    content: "";
  }
  &::before {
    top:0;
    height: 150px;
    background: url(../img/index/blog/blog_t.jpg) no-repeat bottom center;
    background-size: cover;

    @include media_desktop{
      width: 500px;
      height: 178px;
      left: 50%;
      background: url(../img/index/blog/blog_pc_t.jpg) no-repeat bottom center;
      background-size: cover;
      transform: translate(-50%,0);
    };
  }
  &::after {
    bottom: 0;
    height: 370px;
    background: url(../img/index/blog/blog_b.jpg) no-repeat top center;
    background-size: cover;
    @include media_desktop{
      width: 500px;
      height: 178px;
      left: 50%;
      background: url(../img/index/blog/blog_pc_b.jpg) no-repeat bottom center;
      background-size: cover;
      transform: translate(-50%,0);
    };
  }

  .ec-blogRole__khokhloma {
    &::before,&::after {
      position: absolute;
      content: "";
      display: none;
      top:50%;
      transform: translate(0,-50%);
      width: 178px;
      height: 500px;
    }
    @include media_desktop{
      &::before {
        display: block;
        left: 0;
        background: url(../img/index/blog/blog_pc_l.jpg) no-repeat bottom center;
        background-size: cover;
      }
      &::after{
        display: block;
        right:  0;
        background: url(../img/index/blog/blog_pc_r.jpg) no-repeat bottom center;
        background-size: cover;
      }

    }


  }

  .ec-secHeading {
    color: #fff;
    font-size: 3.7rem;
    text-shadow: 0 0 1px #fff;
    z-index: 1;


  }
}
.ec-blogRole {
  position: relative;
  padding: 140px 0 40px;
  z-index: 1;
  @include media_desktop{
    //background: rgba(0,0,0,0.5);
    width: 750px;
    margin: auto;
    padding: 170px 50px 70px;
  };


  &__info {
    padding: 10px;
    background: rgba(0,0,0,0.5);

    @include media_desktop{
      background: rgba(0,0,0,0);
    };

    .blogText {
      margin-bottom: 20px;
      line-height: 1.8;
      @include media_desktop{

        margin: 20px 0;
        font-size: 1.8rem;

        br { display: none;}
      }
    }
    .blogImg {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;


      @include media_desktop{

      }

      &__item {
        width: 49%;

        @include media_desktop{
          width: 49.5%;
        }

        &:nth-child(1),&:nth-child(2) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.ec-categoryRole {
  .categoryList {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 1.2rem;
    @include media_desktop{
      font-size: 1.4rem;
    }

    &-imgType {
      .categoryList__item {
        position: relative;
        width: 33%;
        width: calc(100% / 3);
        height: 150px;
        text-align: center;


        @include media_desktop{
          width: 16.66%;
        }

        &Img {
          position: relative;
          height: 100%;

          .itemImg--radius {
            border-radius: 50%;
          }

          &--img {
            @include position_center;
            width: 80%;
            @include media_desktop{width: 110px;}
          }
          .itemImg--img1{
            width: 45%;
            @include media_desktop{
              width: 65px;
              transform: translate(-50%, -55%);
            }
          }
          .itemImg--img3{
            @include media_desktop{width: 120px;}
          }
          .itemImg--img6{
            width: 45%;
            @include media_desktop{
              width: 55px;
              transform: translate(-50%, -55%);
            }
          }
          .itemImg--img9{
            width: 100%;
            transform: translate(-45%, -50%);
            @include media_desktop{width: 135px;}
          }
          .itemImg--img11{
            width: 60%;
            @include media_desktop{width: 85px;}
          }
          .itemImg--img12{
            width: 100%;
            @include media_desktop{width: 130px;}
          }
          .itemImg--img13{
            width: 90%;
            @include media_desktop{width: 130px;}
          }
          .itemImg--img15{
            width: 85%;
            @include media_desktop{width: 110px;}
          }
          .itemImg--img16{
            width: 86%;
            @include media_desktop{width: 110px;}
          }
          .itemImg--img17{
            width: 90%;
            @include media_desktop{width: 125px;}
          }
        }
        
        &Text {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%,0);
          width: 100%;
        }
      }
    }
    &-textType {
      .categoryList__item {
        margin-bottom: 10px;
        width: 48%;
        border-radius: 5px;
        border: #666 solid 1px;

        a {
          display:block;
          padding: 5px 10px;
        }
      }
    }
  }
}

.ec-newsRole {

  .newsList{
    &__item {
      padding: 15px 0;
      border-bottom: 1px solid #484848;
    }

    &__date {
      display: inline-block;
      padding: 0 5px;
      margin-bottom: 5px;
      background: #484848;
      color: #fff;
      font-size: 1.2rem;
    }
    &__title {

      margin-bottom: 10px;

    }
    &__text {}
  }

}

.ec-igRole {
  .igList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      position: relative;
      width: 33.2%;
      height: 32vw;
      margin-bottom: 0.2%;
      overflow: hidden;

      @include media_desktop {
        width:276px;
        height:276px;
      }

      &:nth-child(9){
        @include media_desktop {
          display: none;
        }
      }

      img { opacity: 0;}

      &--link {
        height: 100%;
        display: block;
        background-position: center center;
        background-size: cover;
      }




    }
  }
}

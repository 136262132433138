@import "../mixins/media";
@import "base";
* {padding: 0;margin: 0;}
ul {
  list-style: none;
  margin-bottom: 0;
}

//drawerRole > matryoshka

//calendar
.calendar {
  margin-left: 20px;
  margin-right: 20px;
  padding:20px;
  border: solid 1px #ddd;
  background: #fff;
  color: #666;
  @include media_desktop{
    margin: 0;
  }

  &__inner {
    @include media_desktop{
      display: flex;
      justify-content: space-around;
    };
  }

  .cal{
    width: 100%;

    &_info {
      padding-top: 20px;
      text-align: left;
      @include media_desktop{
        width: 30%;
      }
    }

    &_wrapper {
      padding: 10px 0px;

      @include media_desktop{
        width: 30%;
      }

      table.cal tr{
        th{
          //background: #fff;



          text-align: center;
          p {

            padding: 5px;
            margin: 0px;
            font-size: 12px;
          }
          div.cal_ui {
            float: right;
          }
          div.cal_ui input {
            border: solid 1px #CCC;
            background-color: #FFF;
            font-size: 12px;
            margin: 0px 1px;
            padding: 1px 5px;
            border-radius: 3px;
          }
        }
        td {
          width: 14.3%;
          width: calc(100%/7);
          //border: solid 1px #ddd;
          border: none;
          padding: 2.5px 0;
          //font-size: 10px;
          text-align: center;
          //padding: 1px;

          div {
          	position: relative;
            padding: 4px 0 4px 0;


          	text-align: center;
          	background-color: #FFF;

            border-radius: 100%;
            height: 27px;
            width: 27px;
            margin: auto;
            background: #eee;


            &.Sat {
              //color: #00F;
              background: #f2f3ff;
            }
            &.Sun {
              //color: #F00;
              background: #ffeded;
            }
            &.Today {font-weight: bolder;}
            &.Deli {background-color: #EFE;}
            &.Holyday,
            &.public_holyday,
            &.holyday, {
            	//font-weight: bolder;
            	color: #fff;
            	background-color: #f97781;
            }
            &.shortday, {
            	//font-weight: bolder;
            	color: #fff;
            	background-color: #748aff;
            }

            &.Birthday {
            	//font-weight: bolder;
            	background-color: #EEF;
            	color: #090;
            	border-radius: 3px;
            }
            &.backward {
            	font-weight: normal;
              //font-size: 0;
              opacity: 0.5;
              color: rgba(0,0,0,0.3);



            }
            &.pointer {
            	//cursor: pointer;
            }
            &.pointer:hover {
              opacity: 0.75;
            	//background-color: #EEE;
            }

            span {
            	display: none;
            	position: absolute;
            	top: 20px;
            	left: 0px;
            	width: 180px;
            	border: solid 1px #EEE;
            	background-color: #FFF;
            	text-align: left;
            	padding: 5px;
            	z-index: 10;
            	color: #000;
            	font-weight: normal;
            	line-height: 1.5em;
            	box-shadow: 1px 1px 3px #666;
            }
          }
        }

        &.headline {
        	//background-color: #EEE;
          border-top: solid 1px #ddd;
          border-bottom: solid 1px #ddd;
        }
      }
    }

  }
  .schedule{
    &_wrapper {
      width: 310px;
      border: solid 1px #CCC;
      border-radius: 5px;
      box-shadow: 1px 1px 3px #666;
    }
    &_list {
    	padding: 0px 10px;
    	margin: 0px;
      ol {
      	padding: 0px;
      	margin: 5px auto;
      	border-top: solid 1px #CCC;
        li {
        	padding: 5px 10px 0px 0px;
        	margin: 0px 0px 0px 30px;
        	font-size: 12px;
        	line-height: 1.5em;
          &:hover {
          	text-decoration: underline;
          }
        }
      }
    }
  }
}

@import "../mixins/media";
@import "base";
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap');

/*
共通コンポーネント

Styleguide 11.0
*/

.have_curtain .ec-overlayRole {
  z-index:1000;
}
.ec-layoutRole__contents {
  padding-top: 60px;
}
.ec-role {
  padding-left: 10px;
  padding-right: 10px;
}

.display{
  &_sp {
    display: block;
    @include media_desktop {
      display: none;
    }
  }
  &_pc {
    display: none;
    @include media_desktop {
      display: block;
    }
  }
}
.more {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
//---------------------------
//header
.ec-layoutRole__header {
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 1000;
}
.ec-headerNaviRole {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  height: 60px;
  //max-width: 100%;
  // padding-top: 0;
  background: #fff;



  &__center {
    @include position_center;
    width: 65px;

  }

}

.ec-headerNav{
  position: relative;
  width: 40px;
  height: 40px;
  color: #fff;
  background: $color_blue;
  cursor: pointer;
  border-radius: 50%;
  //color: $color_blue;
}
.ec-cartNavi {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  color: #fff;
  background: $color_blue;
  cursor: pointer;
  border-radius: 50%;

  &__icon {
    @include position_center ;

    @include media_desktop {
      position: relative;
      left: 0;
      transform: translate(0,-50%);
    }
  }
}
//---------------------------
//footer
.ec-footerRole {
  background: $color_blue;
  text-align: center;
  color: white;

  &__inner{
    color: white;
    @include media_desktop{
      color: white;
    }
  }

  &__logo {
    margin: auto;
    padding: 50px 0 25px;
    width: 90px;
    text-align: center;


  }
  &__navi {
    padding: 0;
    list-style: none;
  }
  .footerNavi {
    @include media_desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__pr {
      @include media_desktop{
        br { display: none;}
        img {width: 140px;}
      }
      .display_sp { font-size: 10px;}

    }
    a {
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none;
      color: white;
      @include media_desktop{
        color: white;
        border-bottom: none;
        text-align: center;
      }
    }
  }
  &__copyright {
    padding: 10px 0 ;
    font-size: 1.1rem;
    background: #00142f;
  }
}

.text_mode {
  p {
    margin-bottom: 15px;
  }
}
.table_simple {
  margin: 0 0 15px;
  border-collapse: unset;
  border-spacing: 1px;

  td {
    padding: 0 15px;

    &.r_color { background: #f1f1f1;}
  }
}
//slick
.slick {
  &-track {
    display: flex;
    justify-content: space-between;
  }
  &-slide {
    float: none;
    height: auto;
  }
}

/*
トップページ

Styleguide 12.0
*/

/*
商品一覧

Styleguide 13.0
*/
.ec-blockBtn--action {
  font-size: 1.2rem;
  @include media_desktop{
    font-size: 1.4rem;
  };
}
.ec-listIntroRole {
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;


  border-top: 1px solid #ccc;
  overflow: hidden;



  @include media_desktop{
    margin: 0 auto;
    padding: 30px 20px 10px;
    max-width: 1130px;
    border: 0;
  }
}
.matryoshka_list {
  display: flex;
  flex-wrap: wrap;
  justify-content:  flex-start;
  @include media_desktop{
    justify-content:  flex-start;
  }

  &__item {
    width: 32.5%;
    width: calc(97.6% /3);
    margin-bottom: 5px;
    margin-right: 1.2%;
    border-radius: 5px;
    overflow: hidden;
    //border: #eee solid 1px;

    &:nth-child(3n) {
      margin-right: 0%;
    }

    @include media_desktop{
      width: 15%;
      margin-right: 2%;
      margin-bottom: 10px;

      &:nth-child(3n) {
        margin-right: 2%;
      }


      &:nth-child(6n) {
        margin-right: 0%;

      }
      // width: calc(98.5%/4);
      // margin-left: 0.5%;

      // &:nth-child(1){margin-left: 0;}
      // &:nth-child(5){margin-left: 0;}
      // &:nth-child(9){margin-left: 0;}
      // &:nth-child(13){margin-left: 0;}
    }

    img {width: 100%;}
    &--name {
      position: relative;
      padding: 5px 0;
      height: 45px;
      background: #042a5c;
      color: #fff;
      font-size: 1.2rem;
      text-align: center;

      p {
        @include position_center;
        width: 100%;
      }
    }
  }
}

.category_text {
  margin-bottom: 20px;

  @include media_desktop{
    display: flex;
    justify-content: space-between;
  };

  &_r {
    @include media_desktop{
      flex-direction: row-reverse;
    }
  }

  img {
    width: 100%;
  }

  &--img {
    margin-bottom: 10px;
    @include media_desktop{
      margin-bottom: 0;
      width: 30%;
    }
  }
  &--text {
    @include media_desktop{
      width: 65%;
    }
  }

}

.product_tags {
  &__item {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    font-weight: bold;
    color: #cc0000;
    border: solid 1px #cc0000;
    border-radius: 3px;
    background-color: #F5F7F8;
  }
}
/*
商品詳細

Styleguide 14.0
*/
.ec-productRole {
  padding-left: 10px;
  padding-right: 10px;
}
.ec-sliderItemRole {
  padding: 0;

  .slideThumb {
    width: 50px;
    padding-right: 5px;

    img { width: 100%;}
  }
}
.item_img {
  @include media_desktop {
    display: none;
  }
}

/*
注文

Styleguide 15.0
*/

/*
注文履歴

Styleguide 16.0
*/

/*
住所一覧

Styleguide 17.0
*/

/*
パスワード再発行

Styleguide 18.0
*/

/*
フォーム

Styleguide 19.0
*/

/*
404ページ

Styleguide 20.0
*/

/*
退会手続き

Styleguide 21.0
*/

/*
会員情報編集完了

Styleguide 22.0
*/
